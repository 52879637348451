<template>
  <div class="d-flex flex-column align-items-center">
    <!--begin::Signin-->
    <div class="login-form login-signin">
      <div class="text-center mb-5 mb-lg-20">
        <img :src="siteLogo()" alt="Logo" width="100%" />
      </div>

      <div class="text-center mb-5 mb-lg-20">
        <!-- <h3 class="font-size-h1">Login</h3> -->
        <p
          class="font-weight-semi-bold mt-7"
          style="color: white;font-size:20px;"
        >
          It's another beautiful Yardnique day!
        </p>
        <!-- <p>Logging you in...</p> -->
      </div>

      <!--begin::Form-->
      <v-form class="form" @submit.stop.prevent="onSubmit">
        <div role="alert" v-if="errors && errors.length">
          <v-alert border="right" colored-border type="error" elevation="2">
            {{ errors }}
          </v-alert>
        </div>

        <v-container fluid>
          <v-text-field
            class="mt-4"
            background-color="white"
            outlined
            dense
            color="#F06835"
            label="Enter your email"
            required
            v-model="$v.form.username.$model"
            :error-messages="usernameErrors"
            @input="$v.form.username.$touch()"
            @blur="$v.form.username.$touch()"
          ></v-text-field>

          <v-text-field
            class="mt-4"
            background-color="white"
            outlined
            dense
            color="#F06835"
            label="Enter your password"
            :type="showPassword ? 'text' : 'password'"
            @click:append.prevent="showPassword = !showPassword"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            required
            v-model="$v.form.password.$model"
            :error-messages="passwordErrors"
            @input="$v.form.password.$touch()"
            @blur="$v.form.password.$touch()"
          ></v-text-field>
        </v-container>

        <div class="form-group d-flex flex-column align-items-center">
          <a
            href="#"
            class="password-reset text-dark-60 my-3 mr-2"
            @click="goToForgotPassword"
            style="font-size: 16px;;"
          >
            Forgot Password ?
          </a>
          <v-btn
            type="submit"
            color="#F06835"
            dark
            class="mb-2 mt-2"
            style="min-width: 188px; width: 100%; font-size:larger;"
            :loading="isSubmitting"
          >
            Log in
          </v-btn>
        </div>
      </v-form>
      <!--end::Form-->
    </div>

    <!--end::Signin-->

    <div
      class="text-center"
      style="padding: 10px; margin-top: 50px; border: white 2px solid; max-width: 500px;"
    >
      <div class="text-center mt-5 mb-5 mb-lg-20">
        <!-- <img :src="solivitaLogo()" alt="Solivita Logo" width="50%" /> -->
        <h3>Resident User Registration</h3>
      </div>
      <p class="text-center" style="color: black; font-size:20px;">
        If you're a resident and need access to syncscape, register here. If you
        already have access to synscape, you do not need to register.
      </p>
      <v-btn
        color="#F06835"
        dark
        class="mb-2 mt-2"
        style="min-width: 188px; width: 100%; font-size:larger;"
        @click="goToResidentRegistration()"
      >
        Resident Syncscape Registration
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { LOGIN, LOGOUT, REFRESH } from "@/core/store/auth.module";
import { mapGetters } from "vuex";
import objectPath from "object-path";
import { validationMixin } from "vuelidate";
import { email, minLength, required } from "vuelidate/lib/validators";
import Mgr from "@/core/services/security.service";

export default {
  mixins: [validationMixin],
  name: "login",
  data() {
    return {
      mgr: new Mgr(),
      isSubmitting: false,
      showPassword: false,
      form: {
        username: null,
        password: null,
        remember: false
      }
    };
  },
  validations: {
    form: {
      username: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(3)
      }
    }
  },
  async created() {
    // window.confirm("login:load");
  },
  async mounted() {
    await this.redirectToPortal();
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    ...mapState({
      errors: state => state.auth.errors,
      isAuthenticated: state => state.auth.isAuthenticated
    }),
    usernameErrors() {
      const errors = [];
      if (!this.$v.form.username.$dirty) return errors;
      !this.$v.form.username.email && errors.push("Must be valid e-mail");
      !this.$v.form.username.required && errors.push("Email is required");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.form.password.$dirty) return errors;
      !this.$v.form.password.minLength &&
        errors.push("Must be at least 8 characters long");
      !this.$v.form.password.required && errors.push("Password is required");
      return errors;
    }
  },
  methods: {
    solivitaLogo() {
      return process.env.BASE_URL + "media/bg/SolivitaLogo_RegPage.png";
    },
    async goToResidentRegistration() {
      await this.$router.push({ name: "ResidentRegistration" });
    },
    async goToForgotPassword() {
      await this.$router.push({ name: "forgotPassword" });
    },
    async redirectToPortal() {
      let self = this;
      try {
        if (!this.isAuthenticated) {
          return;
        }
        let user = await self.mgr.getUser();
        // console.log("let user = await self.mgr.getUser();", user)
        // window.confirm("login:user" + user);

        if (user == null || !user.role) {
          // window.confirm("login:user = null");
          //await self.mgr.signOut();

          // await self.mgr.signIn();
          await this.$store.dispatch(REFRESH);
          user = await self.mgr.getUser();

          // console.log("if user == null then user = await self.mgr.getUser();", user)
        }
        if (user != null && user.role) {
          const customerViewRoles = [
            "Property Manager",
            "Board Member",
            "Resident",
            "Tenant/Resident",
            "Tenant/Resident - Neighborhood",
            "Landscape Committee"
          ];

          if (customerViewRoles.indexOf(user.role) > -1) {
            window.location.href = "/#/customerView/dashboard";
          } else {
            // console.log("user role", user.profile.aspireRole);
            if (user.role == "Crew Leader") {
              window.location.href = "/#/create-orange-report";
              //               this.$router.push({
              //   name: "CrewLeaderOrangeReportNew",

              // });
            } else window.location.href = "/#/customers";
          }
        }

        // self.mgr
        //   .getUser()
        //   .then(function(user) {

        //   })
        //   .catch(function() {
        //     //console.log(err);
        //     self.mgr.signOut();
        //   });
      } catch (ex) {
        //console.log(ex);
        window.confirm("login:catch" + ex);
        await self.mgr.signOut();
      }
    },
    siteLogo() {
      const menuAsideLeftSkin = this.layoutConfig("brand.self.theme");
      // set brand logo
      const logoObject = this.layoutConfig("self.logo");

      let logo;
      if (typeof logoObject === "string") {
        logo = logoObject;
      }
      if (typeof logoObject === "object") {
        logo = objectPath.get(logoObject, menuAsideLeftSkin + "");
      }
      if (typeof logo === "undefined") {
        const logos = this.layoutConfig("self.logo");
        logo = logos[Object.keys(logos)[0]];
      }
      return process.env.BASE_URL + logo;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        email: null,
        password: null,
        remember: false
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    async onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      this.errorMessage = "";

      const username = this.$v.form.username.$model;
      const password = this.$v.form.password.$model;

      // clear existing errors
      await this.$store.dispatch(LOGOUT, this.$route.query);

      // // set spinner to submit button
      // const submitButton = this.$refs["kt_login_signin_submit"];
      // submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      await this.$store.dispatch(LOGIN, { username, password });

      if (this.errors.errors) {
        if (Array.isArray(this.errors.errors))
          this.errorMessage = this.errors.errors
            .map(({ message }) => message)
            .join("; ");
        else this.errorMessage = this.errors.errors;
      }

      // submitButton.classList.remove(
      //   "spinner",
      //   "spinner-light",
      //   "spinner-right"
      // );

      if (this.isAuthenticated) {
        if (this.$route.query.redirect) {
          await this.$router.push(this.$route.query.redirect);
        } else {
          //await this.$router.push({ name: "Customers" });
          await this.redirectToPortal();
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.password-reset {
  font-weight: 500;
  color: black;
  &:hover {
    color: #ab2f75;
  }
}

p,
label {
  color: white;
}

input[type="text"] {
  background-color: white;
}
</style>
